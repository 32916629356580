body
{

	/*background-color: #f0f0f0 !important;*/
}


body.empty
{
	/*background-color: #f0f0f0 !important;*/
}

body.fixed-top
{
	padding-top:100px;
}

nav#main-navbar {
    background-color: #0275d8;
}

nav#main-navbar a.navbar-brand {
    background-color: transparent;
    border-bottom: 0px;
	width: auto;
	letter-spacing: -0.1pt;
	color: #fff;
}

.page-heading{
	margin-top:20px;
}

.page-heading h1 {
    font-size: 1.65rem;
    font-weight: 300;
    color: #606060;
    margin-top:2px;
	margin-bottom: 15px;
}



#event_name_link {
	background-color: rgba(0, 0, 0, 0.1);
	min-width: 100px;
	text-align: center;
	cursor:default;
	text-decoration: none;
	cursor: pointer;
}

#event_name_link:hover {
	background-color: rgba(0, 0, 0, 0.2);
	text-decoration: none;
}

/*

.dropdown-menu {
	border-color: #a6a6a6;
}


#clock-wrapper{
	background-color: rgba(0,0,0,0.1);
}
#clock {
	font-size: 14pt;
	margin: 12px;
}

@media (max-width: 768px) {
	#clock-wrapper {
		position: absolute;
		left: 10px;
		bottom: 0;
		line-height: 49px;
	}
}*/

.login-box-body, .register-box-body {
	border-radius: 8px;
}

.content-header>h1 {
	margin: 0;
	font-size: 24pt;
}

.form-control {
	border-radius: 5px;
	box-shadow: none;
	border-color: #d5d5d5;
}


.content-header {
    position: relative;
    padding: 30px 15px 0 15px;
}

.content-header>h1 {
    margin: 0;
    font-size: 19pt;
    font-weight: 100;
    color: #434343;
}


body.boxed-master .content-wrapper{

}

mark, .mark {
	padding: .2em;
	background-color: rgba(255, 235, 0, 0.77);
	border-radius: 4px;
}

#events_table {
	font-size: 1.3em;
}
#events_table tbody tr{
	cursor: pointer;
}
#events_table tbody tr:hover{
	background-color: #518dfd;
	color: #fff;
}

#competitors_table{
	font-size: 1.2em;
}

#competitors_table tbody tr{
	cursor: pointer;
}
#competitors_table tbody tr:hover{
	background-color: #518dfd;
	color: #fff;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #acacac;
    text-transform: uppercase;
    letter-spacing: 0.1px;
}


.overflow-x-scroll {
	overflow-x: scroll !important;
}
.no-padding {
	padding: 0 !important;
}


.btn-block-on-center {
	margin: auto;
    display: block;
    width: 100%;
    box-sizing: border-box;    
}
